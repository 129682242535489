import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function transformTimeZone(date: Date) {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  return dayjs.utc(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD').toDate()
}
